<template>
  <v-container fluid>
    <v-row
      class="mx-auto"
      v-for="category in categories"
      :key="category.name"
    >
      <v-col cols="12">
        <h2>{{ category.name }}</h2>
      </v-col>
      <v-col
        xs="12"
        sm="6"
        md="4"
        xl="3"
        v-for="(item, _key) in category.itens"
        :key="_key"
      >
        <v-hover v-slot:default="{ hover }">
          <a
            :href="item.html_link"
            style="text-decoration: none"
          >
            <v-card
              :id="_key"
              class="mx-auto my-auto px-2 d-flex"
              width="352px"
              height="100px"
              :color="hover ? 'grey lighten-2' : 'grey lighten-3'"
              elevation="1"
              :to="item.link"
              :disabled="item.disabled"
            >
              <v-row
                no-gutters
                align="center"
                justify="center"
              >
                <v-col cols="2">
                  <v-icon
                    class="my-auto d-flex justify-center"
                    :size="item.icon_size || 40"
                    color="primary"
                  >
                    {{ item.icon }}
                  </v-icon>
                </v-col>
                <v-col cols="10">
                  <v-card-text class="mx-auto my-auto">
                    <div
                      class="subtitle-2"
                      style="word-break: normal"
                    >
                      {{ item.name }}
                    </div>
                    <div class="caption text--secondary">
                      {{ item.description }}
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </a>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ServidoresAwsEc2Service from '@/services/aws/ServidoresAwsEc2Service';
import ServidoresAwsRdsService from '@/services/aws/ServidoresAwsRdsService';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  mixins: [profilePermissionsMixin],
  data: () => ({
    habilitaTelaServidoresEc2NaAws: false,
    habilitaTelaServidoresRdsNaAws: false
  }),
  mounted() {
    this.getHabilitaTelaServidoresEc2NaAws();
    this.getHabilitaTelaServidoresRdsNaAws();
  },
  methods: {
    getHabilitaTelaServidoresEc2NaAws() {
      ServidoresAwsEc2Service.getHabilitaTelaServidoresEc2NaAws().then(
        ({ data }) => (this.habilitaTelaServidoresEc2NaAws = Boolean(data))
      );
    },
    getHabilitaTelaServidoresRdsNaAws() {
      ServidoresAwsRdsService.getHabilitaTelaServidoresRdsNaAws().then(
        ({ data }) => (this.habilitaTelaServidoresRdsNaAws = Boolean(data))
      );
    }
  },
  computed: {
    categories() {
      const categories = [];

      if (
        this.accessReleased('USUARIOS_LISTAR') ||
        this.accessReleased('PERFIS_USUARIOS_LISTAR') ||
        this.accessReleased('LOGS_ACESSOS_USUARIOS_LISTAR') ||
        this.accessReleased('EMPRESAS_LISTAR')
      ) {
        categories.push({
          name: 'Autenticação e autorização de usuários',
          itens: []
        });
      }

      if (this.accessReleased('USUARIOS_LISTAR')) {
        categories[0].itens.push({
          name: 'Usuários',
          link: 'usuarios/listar',
          description: 'Crie, edite e visualize os usuários do sistema',
          icon: 'mdi-account-supervisor'
        });
      }

      if (this.accessReleased('PERFIS_USUARIOS_LISTAR')) {
        categories[0].itens.push({
          name: 'Perfis de Usuários',
          link: 'perfis-usuarios/listar',
          description:
            'Crie, edite e visualize os perfis de usuários do sistema',
          icon: 'mdi-account-group'
        });
      }

      if (this.accessReleased('LOGS_ACESSOS_USUARIOS_LISTAR')) {
        categories[0].itens.push({
          name: 'Logs de acessos de usuários',
          link: 'logs-acessos-usuarios/listar',
          description: 'Visualize os logs de acessos de usuários',
          icon: 'mdi-clock'
        });
      }

      if (this.accessReleased('EMPRESAS_LISTAR')) {
        categories[0].itens.push({
          name: 'Empresas',
          link: 'empresas/listar',
          description: 'Crie, edite e visualize as empresas do sistema',
          icon: 'mdi-office-building-marker'
        });
      }

      if (
        this.accessReleased('GEOPERDAS_SERVIDORES_BANCOS_DADOS_LISTAR') ||
        this.accessReleased('ORACLE_SERVIDORES_BANCOS_DADOS_LISTAR') ||
        this.accessReleased('SERVIDORES_EC2_NA_AWS_LISTAR') ||
        this.accessReleased('SERVIDORES_RDS_NA_AWS_LISTAR') ||
        process.env.NODE_ENV === 'development'
      ) {
        categories.push({
          name: 'Servidores e aplicações externas',
          itens: []
        });
      }

      if (this.accessReleased('GEOPERDAS_SERVIDORES_BANCOS_DADOS_LISTAR')) {
        categories[1].itens.push({
          name: 'SQL Server GeoPerdas',
          link: 'geo-perdas/servidores-bancos-dados/listar',
          description:
            'Configure conexões a bancos de dados SQL Server GeoPerdas',
          icon: 'mdi-database-cog'
        });
      }

      if (this.accessReleased('ORACLE_SERVIDORES_BANCOS_DADOS_LISTAR')) {
        categories[1].itens.push({
          name: 'Oracle Extração BDGD',
          link: 'oracle/servidores-bancos-dados/listar',
          description:
            'Configure conexões a bancos de dados Oracle para extração de BDGD Bruta',
          icon: 'mdi-database-cog'
        });
      }

      if (process.env.NODE_ENV === 'development') {
        categories[1].itens.push({
          name: 'GeoServer',
          link: null,
          html_link: process.env.VUE_APP_GEOSERVER_BASE_URL,
          description: 'Acesse a interface web de administração do GeoServer',
          icon: 'mdi-server-network'
        });
        categories[1].itens.push({
          name: 'Portainer',
          link: null,
          html_link: process.env.VUE_APP_PORTAINER_BASE_URL,
          description: 'Acesse a interface web do Portainer Docker',
          icon: 'mdi-docker'
        });
      }

      if (
        this.habilitaTelaServidoresEc2NaAws &&
        this.accessReleased('SERVIDORES_EC2_NA_AWS_LISTAR')
      ) {
        categories[1].itens.push({
          name: 'Servidores EC2 na AWS',
          link: 'aws/servidores-ec2/listar',
          description:
            'Ligue e desligue servidores da aplicação no serviço EC2 da AWS',
          icon: 'mdi-aws'
        });
      }

      if (
        this.habilitaTelaServidoresRdsNaAws &&
        this.accessReleased('SERVIDORES_RDS_NA_AWS_LISTAR')
      ) {
        categories[1].itens.push({
          name: 'Servidores RDS na AWS',
          link: 'aws/servidores-rds/listar',
          description:
            'Ligue e desligue servidores de banco de dados no serviço RDS da AWS',
          icon: 'mdi-aws'
        });
      }

      if (this.accessReleased('RELATORIOS_MODELOS_HISTORICO')) {
        categories.push({
          name: 'Relatórios',
          itens: [
            {
              name: 'Relatórios Modelos',
              link: 'relatorios-modelos/historico',
              description:
                'Gerencie seus grupos e templates de relatórios modelos',
              icon: 'mdi-file-settings'
            }
          ]
        });
      }

      if (this.accessReleased('TABELAS_X_DISCO')) {
        categories.push({
          name: 'Armazenamento',
          itens: [
            {
              name: 'Tabelas x Disco',
              link: 'armazenamento/tabelas-x-disco',
              description:
                'Visualize o espaço que cada tabela do sistema ocupa no disco do servidor',
              icon: 'mdi-harddisk'
            }
          ]
        });
      }

      return categories;
    }
  }
};
</script>
