import APIService from '@/services/APIService.js';

export default {
  getServidores() {
    return APIService.apiCall().get('/aws/servidores-ec2/listar');
  },
  getHabilitaTelaServidoresEc2NaAws() {
    return APIService.apiCall().get('/aws/servidores-ec2/get-habilita-tela-servidores-ec2-na-aws');
  },
  getNomeInstanciaAtual() {
    return APIService.apiCall().get('/aws/servidores-ec2/get-nome-instancia-atual');
  },
  getNomeInstanciaNuncaDesligar() {
    return APIService.apiCall().get('/aws/servidores-ec2/get-nome-instancia-nunca-desligar');
  },
  ligarServidores(postData) {
    return APIService.apiCall().post('/aws/servidores-ec2/ligar', JSON.stringify(postData));
  },
  desligarServidores(postData) {
    return APIService.apiCall().post('/aws/servidores-ec2/desligar', JSON.stringify(postData));
  },
};
