import APIService from '@/services/APIService.js';

export default {
  getServidores() {
    return APIService.apiCall().get('/aws/servidores-rds/listar');
  },
  getHabilitaTelaServidoresRdsNaAws() {
    return APIService.apiCall().get('/aws/servidores-rds/get-habilita-tela-servidores-rds-na-aws');
  },
  ligarServidores(postData) {
    return APIService.apiCall().post('/aws/servidores-rds/ligar', JSON.stringify(postData));
  },
  desligarServidores(postData) {
    return APIService.apiCall().post('/aws/servidores-rds/desligar', JSON.stringify(postData));
  },
};
